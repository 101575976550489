<template>
  <div class="flex-grow-1">
    <AdminTitle title="School Profile">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn 
            v-if="school"
            :to="{ name: 'School', query: { id: school.id } }"
            color="accent gradient"
            class="mr-2"
          >
            Edit
          </v-btn>

          <v-btn
            @click="getUserSchool()" 
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <!-- Filters -->
    <custom-alert v-if="!status.getting && !Object.keys(school).length">
      Click <router-link :to="{ name: 'School' }">here</router-link> to create your school profile and get started.
    </custom-alert>

    <div v-if="!status.getting && Object.keys(school).length">
      <v-row>
        <v-col cols="12">
          <v-row v-if="school.logo" class="mb-5" no-gutters>
            <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
              <school-logo :filename="school.logo" />
            </v-col>
          </v-row>

          <h5 class="text-h5 font-weight-black mb-3">{{ school.name }}</h5>
          <div class="d-flex flex-column">
            <div v-if="school.address" class="mb-2">
              <span>
                <span>{{ [`${school.address.street_number || '' } ${school.address.route || ''}`, school.address.locality, school.address.administrative_area_level_1].join(', ') }}</span>
              </span>
            </div>

            <div v-if="school.website" class="mb-2">
              <a
                class="text-decoration-none underline-on-hover info--text"
                :href="school.website" 
                target="_blank"
              >
                {{ school.website }}
              </a>
            </div>

            <div class="mb-2">{{ school.type }}</div>
            <div>{{ school.locale }}</div>

            <!-- about me section -->
            <div class="d-flex flex-column mt-4">
              <div class="mt-4 mb-9 text-pre-line">
                {{ school.introduction }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import _orderBy from "lodash/orderBy"
import SchoolLogo from './SchoolLogo'

export default {
  name: "Schools",

  metaInfo: {
    title: "Schools",
  },

  data() {
    return {
      orderBy: "desc",
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    SchoolLogo
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      school: (state) => state.schools.school,
      status: (state) => state.schools.status,
    }),

    ordered: function () {
      return _orderBy(this.schools, "createdAt", this.orderBy)
    },
  },

  methods: {
    ...mapActions("schools", ["getUserSchool"]),

    filterOrder(sortData) {
      this.orderBy = sortData.order
    },
  },

  created() {
    this.getUserSchool()
  },
}
</script>